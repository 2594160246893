import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'

import Toast from "vue-toastification"

// Import the CSS or use your own!
import "vue-toastification/dist/index.css"
const options = {
    // You can set your default options here
    toastClassName: " shadow-xl  ",
    //bodyClassName: ["p-0 text-xxs", ""]

};





createApp(App)
    .use(store)
    .use(router)
    .use(Toast, options)
    .mount('#app')
