<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <div class="max-w-7xl mx-auto px-10">
    <div class="flex xl:text-lg lg:text-base sm:text-sm gap-5 mx-auto">
        <div class="mx-auto">
          <!-- <FlipDown :endDate="new Date(2022, 1, 15, 2, 3, 4, 567)" :theme="2" class=" font-extrabold text-2xl text-yellow-400" :type="4" :timeUnit="['days','hours','m','s until start.']" />  -->
           </div>
    </div>

<div class="grid grid-cols-2 gap-5 mx-auto">
    <div class="flex xl:text-lg lg:text-base sm:text-sm gap-5 py-4">
        <div class="flex flex-grow auto rounded bg-white p-3 text-xs shadow transition transform hover:shadow-xl hover:bg-white cursor-pointer ">
          <div class=" mx-auto text-gray-700 text-justify"><b>NFTy.today</b> is an automated Artificial Intelligence engine. <br/><br/>
          It empowers all NFT collections with a fair run, overcoming rug-pulls, with no involvement from humans, sells and distribute proceeds, creates DAO Rules and maintain your NFT project and associated tokens in an autonomous schedule. 
          It sold out 32 collections in less than 3 minutes with a volume of +15.6k eth and +21k sol. It automates programmed operations with no organic intervention, clear and simple.
            <br/><br/><b>
               the DAO is real! Are you ready?</b></div>
        </div>
    </div>

    <div class="flex flex-grow py-4">
        <div class="rounded bg-white p-3 text-xs shadow transition transform  hover:shadow-xl hover:bg-white cursor-pointer">
          <div class="text-xs text-justify p-3 w-full">This DAO lives as long as the blockchain that was created on lives... it outlives its creator... <i class="fas fa-hand-spock text-yellow-500"></i>
            <br/>Each NFT owner of any collection made by this DAO, will get one invite code at the end of the sale.<hr class="my-4"/>Interested in finding out more?</div>
            <router-link to="/early-access-nft-collections-presale" tag="button">
              <button class="w-full bg-gray-700 flex flex-grow rounded p-2 text-white transition transform hover:shadow-xl" >Got an invite code? Register now!</button>
            </router-link>
        </div>
    </div>

    
</div>
    </div>
  </div>
</template>

<script>
import {ref,watchEffect} from 'vue'
import axios from 'axios'
import { useToast } from "vue-toastification";
import  FlipDown  from 'vue-flip-down'
 
 
export default {
  name: 'Home',
  components: {
    //HelloWorld
    //ListenUsers
    FlipDown
  },

setup() {
const toast = useToast()
  
    return {
      //listenUsr,
      //handleStop
    }

  }
}
</script>
