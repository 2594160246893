<template>
<div class="flex flex-col h-screen justify-between">
  <div class="container flex mx-auto justify-between items-center  px-9 xl:text-lg lg:text-base sm:text-sm">
      <!-- left side -->
      <div class="hidden lg:flex justify-items-stretch items-center ">
        <router-link to="/"><img src="./assets/img/logo.NFTy.today.beta.png" width="190" alt="logo" class=" transform hover:scale-150  transition duration-500 ease-in-out cursor-pointer"/></router-link>
        <router-link class="inline-block lg:text-xs xl:text-base px-3 text-blue-500 no-underline font-bold transform hover:scale-110 hover:shadow-xl hover:bg-yellow-300 hover:text-gray-900 hover: rounded transition duration-300 ease-in-out " to="/">home</router-link> 
        <router-link class="inline-block lg:text-sm xl:text-base px-3 text-blue-500 no-underline font-bold transform hover:scale-110 hover:shadow-xl hover:bg-yellow-300 hover:text-gray-900 hover: rounded transition duration-300 ease-in-out " to="/early-access-nft-collections-presale">early access</router-link> 
        
      </div>
      <!-- Mobile menu button -->
<div class="sm:hidden flex items-center">
  <router-link to="/"><img src="./assets/img/logo.NFTy.today.beta.png" width="190" alt="logo" class="hidden transform hover:scale-150  transition duration-500 ease-in-out cursor-pointer"/></router-link>
	<button class="outline-none mobile-menu-button">
		<svg
			class="w-6 h-6 text-yellow-500"
			x-show="!showMenu"
			fill="none"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="2"
			viewBox="0 0 24 24"
			stroke="currentColor"
		>
		<path d="M4 6h16M4 12h16M4 18h16"></path>
		</svg>
	</button>
</div>
      <!-- right side -->
      <div class="">
        
      </div>

  
</div>

<router-view  />




  <footer class="h-250 bg-footer-back bg-fixed">
    <div class="container flex mx-auto justify-between items-center  px-9 xl:text-lg lg:text-base sm:text-sm ">
      <div class="flex flex-wrap gap-5 mx-auto min-w-full p-10 cursor-pointer">

  
  
  
  <div class="bg-white text-sm min-h-full my-auto rounded shadow transform duration-200 hover:scale-105 hover:shadow-xl max-w-xs">
     <div class="grid grid-cols-1 sm:grid-cols-2 mx-auto min-w-full">
       <div class="p-3  align-middle">
         <img src="@/assets/img/blockchains.png" class="mx-auto my-auto max-w-100">
       </div>
       <div class="p-3 text-right">
         <span class="text-center font-extrabold">Blockchains</span>
         <p class="text-xs text-right">At the moment we provide services for Ethereum and Solana NFT Collections. As soon as we will approach other blockchain projects, we will make them available here.</p>
       </div>
     </div>
  </div>
  <div class=" max-w-xs bg-white text-sm min-h-full my-auto rounded shadow transform duration-200 hover:scale-105 hover:shadow-xl">
     <div class="grid grid-cols-1 sm:grid-cols-2 mx-auto min-w-full">
       <div class="p-3  align-middle">
         <img src="@/assets/img/misteryBox.png" class="mx-auto my-auto max-w-100">
       </div>
       <div class="p-3 text-right">
         <span class="font-extrabold ">mistery NFT box</span>
         <p class="text-xs text-justify">A few mistery boxes will be available as soon as some collections go live on Solana or Ethereum. Minting will be done for 5 to 10 NFTs on 3 collections or more, deliverable in 24h. <br/>Stay tuned!</p>
       </div>
     </div>
  </div>
  <div class=" max-w-xs bg-white rounded shadow transform duration-300 p-4 hover:scale-105 hover:shadow-xl">
      <ul class="list-none md:list-inside text-xs text-left">
        <p class="text-justify">We do our best to provide early access to all the collections we curate and nurture. 
          NFTy.today was created to discover the best Non Fungible Tokens on all possible blockchains to date, including Ethereum, Solana & others. 
          We give public access to every artist, programmer and gallery, to publish, create and promote their Non Fungible Projects. 
          <br/><br/><b>Together we are strong!</b></p>
      </ul>
  </div>
  <div class=" max-w-xs bg-white rounded shadow transform duration-300 p-4 hover:scale-105 hover:shadow-xl" style="background-image: url('@/assets/img/nftyGolden.png')">
      <img src="@/assets/img/nftyGolden.png">
      <span class="text-xs text-yellow-500">art . non-fungible-tokens . decentralised </span>
      <br/>
      <span class="text-xs text-yellow-500">fully autonomous</span>
  </div>

  <div class="col-span-3 text-xxs ">&copy; {{ new Date().getFullYear() }} <span class="text-blue-900 font-extrabold">NFTy.today</span> all rights reserved
  |
   DAO spreading <i class="fas fa-heart text-red-500"></i>, 
   &nbsp;<i class="fas fa-mug-hot text-red-900"></i> to devs & 
   &nbsp;<i class="fab fa-ethereum text-gray-600"></i>
   &nbsp;⦾
   &nbsp;<i class="fab fa-bitcoin text-yellow-500"></i> distribution to everyone involved.</div>
</div>
    </div>
  </footer>
</div>


</template>



<script>
import VueMeta from 'vue-meta'
export default {
  setup() {
    document.body.classList.add('bg-gray-90')
    //return true
  },
//   {
//   metaInfo: {
//     meta: [
//       { charset: 'utf-8' },
//       { name: 'viewport', content: 'width=device-width, initial-scale=1' }
//     ]
//   }
// },
}
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

}

</style>
